import { AxiosError } from 'axios'

import {
  ErrorResponse,
  HttpStatus,
  RequestHeaders,
  RequestInterceptor,
  ResponseInterceptor,
} from '@/app/types/api/config'

import cookies from '@/plugins/cookies'
import i18n from '@/plugins/i18n'
import Store from '@/store'

import ErrorResponseInterceptorNotify, {
  InterceptorNotifyName,
} from '@/app/api/factories/configs/ErrorResponseInterceptorNotify'
import env from '@/app/env'
import ClientResponseError from '@/app/errors/ClientResponseError'
import logoutAndReset from '@/app/helpers/logoutAndReset'

const getToken = () => {
  const token = cookies.get(env.cookieName)

  return token ? 'Bearer ' + token : ''
}

function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message =
    error.response?.data?.message ?? i18n.global.t('errors.standardServerError')
  const errors = error.response?.data?.errors

  switch (error?.response?.status) {
    case HttpStatus.PaymentRequired:
      return [i18n.global.t('errors.paymentFailed')]
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message]
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')]
    default:
      return [message]
  }
}

const requestInterceptors: RequestInterceptor = {
  onSuccess(config) {
    const { state } = Store

    const orgId = state.organizations.currentOrganizationId

    if (config.headers) {
      if (config.headers[RequestHeaders.Localization] === undefined) {
        config.headers[RequestHeaders.Localization] = i18n.global.locale.value
      }

      if (orgId) {
        config.headers[RequestHeaders.OrganizationId] = orgId
      }
    }

    return config
  },
  onError(error) {
    return Promise.reject(error)
  },
}

const responseInterceptors: ResponseInterceptor = {
  onSuccess(response) {
    return response
  },
  onError(error: AxiosError<ErrorResponse>): Promise<ClientResponseError> {
    if (error.response?.status === HttpStatus.Unauthorized) {
      logoutAndReset()
    }

    const messages = getErrorMessages(error)
    const notify = new ErrorResponseInterceptorNotify(error, messages)

    notify.create()

    const isTwoFactorAuth =
      notify.notifyName === InterceptorNotifyName.TwoFactorAuthorization

    return Promise.reject(
      new ClientResponseError({
        name: error.name,
        message: error.message,
        status: error.response?.status,
        errors: error.response?.data?.errors ?? null,
        isTwoFactorAuth,
        notify: notify.options,
        messages,
      }),
    )
  },
}

export default {
  baseURL: env.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
  },
  timeout: 20000,
  requestInterceptors,
  responseInterceptors,
}
